import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SubNav from '../../components/sub-nav';
import SEO from '../../components/seo';
import AboutHeader from '../../images/crash-management/about-header.jpg'
import AccidentSubNav from "../../components/accident-sub-nav";

const AboutAccidentManagement = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  if (data.general.country_code === 'nz') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | About Accident Management" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={AboutHeader}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>About Crash Management</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <h4><strong>Crash Management is New Zealand’s premier 24/7 vehicle accident and repair management
                      provider</strong></h4>
                    <p>We’ve been operating since 2004, are completely independent and New Zealand run and
                      operated.&nbsp; We’re the No.1 preferred accident management &amp; collision repair service for
                      private motorists, fleet operators, insurance brokers and vehicle leasing firms nationwide. Crash
                      Management was the winner of the prestigious <strong>Insurance Industry Award 2009 for Innovation
                        of
                        the Year.&nbsp;</strong></p>
                    <p>The service is modular and fully customisable. From the core service suitable for privately owned
                      vehicles, a range of enhanced resources and tools are available to tailor wrap-around solutions
                      for
                      fleet operators of all sizes. Our fleet clients and alliance partners include some of NZ’s best
                      known brands. See our&nbsp;<a
                        href="https://www.dinggo.co.nz/accident-management/testimonials">Testimonials</a>&nbsp;page for
                      a small
                      selection of references.</p>
                    <p>Crash Management contracts over 100 quality collision repair facilities nationwide including
                      insurance company approved and certified for structural repairs. All locations have access to
                      manufacturers’ collision repair specifications. These repair methodologies and technical standards
                      protect the integrity and value of vehicles after repair, and on-going driver safety.</p>
                    <p>Our friendly customer service staff (the Crash Team) have insurance or trade-based experience and
                      technical expertise. We provide customers with fast effective support and advice, from the scene
                      of
                      the accident right through to the day of re-delivery. Your dedicated Case Manager will be your
                      advocate throughout the service experience.</p>


                    <div
                      className="wp-block-columns is-layout-flex wp-container-core-columns-layout-1 wp-block-columns-is-layout-flex">
                      <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                        <h3 className="wp-block-heading">The Crash Management advantage</h3>


                        <p>Crash Management delivers unique resources and benefits to save customers time and resources,
                          re-mobilise quickly, repair and return vehicles to service fast. Out-sourcing accident
                          management is the intelligent cost-effective alternative to the old DIY approach that leaves
                          drivers and managers doing all the work. We don’t want our customers running around panel
                          shops
                          for repair quotes, putting up with poor customer service standards, or catching the bus while
                          the car’s being repaired! We combine great technology and automation with service excellence
                          and
                          professional friendly expertise.</p>


                        <p>With a focus on efficiency, service excellence and doing old things in smart new ways, Crash
                          Management is the only resource you need when a vehicle accident happens. Just one call to
                          0800
                          2CRASH has everything organised – from the crash scene until the vehicle is back on the road
                          again. Get fleet fit with Crash Management – &nbsp;the process and insurance claim formalities
                          are fast-tracked, disruption is minimised, damage assessments and repair service is
                          prioritised
                          and the vehicle is back on the road faster.</p>
                      </div>

                    </div>


                    <h3>Cloud-based portal</h3>
                    <p>We utilise a secure cloud-based operating portal for customer convenience. This provides secure
                      login access and improves communications between customers, repairers, assessors, brokers and
                      insurers. Daily progress tracking and reporting automatically transmits regular progress updates
                      to
                      customers including the target completion date so drivers know what’s happening every step of the
                      way.&nbsp; The platform includes an easy to read dash-board, robust record keeping, delivers full
                      transparency, and is auditable.</p>
                    <h3>Management Reports</h3>
                    <p>A wide selection of fleet management reports are available including activity summaries by date
                      range, claim cycle-time tracking, and detailed incident data collation &amp; reporting.
                      Intelligent
                      incident analysis delivers a clear understanding of incident trends and causes including
                      recidivist
                      drivers, and supports targeted remedial action that is proven to help reduce incident rates for
                      fleet operators.</p>
                    <h3>Fleet driver Health &amp; Safety</h3>
                    <p>Any vehicle used for business purposes is deemed to be&nbsp;“<em>a place of work”</em>&nbsp;so
                      fleet operations are subject to the H&amp;S obligations imposed by the Act. Employers must
                      take&nbsp;<em>“all practical steps”</em>&nbsp;to address vehicle hazard and ensure the business is
                      fleet fit.&nbsp; Crash Management can assist with a comprehensive range of accident prevention
                      strategies and products that are proven to help reduce accident rates. Unfortunately car accident
                      claims can’t be completely eliminated, but a tailored 24/7 emergency response and driver support
                      programme will provide peace of mind when incidents do occur.</p>
                    <h3>Want to learn more about our comprehensive fleet incident response &amp; risk management
                      solutions?</h3>
                    <p>Good decision!&nbsp; Crash Management will save your business and your fleet time, money,
                      disruption and help reduce accident rates. For a no obligation information pack or a confidential
                      discussion of your fleet environment and needs, contact <a
                        href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz</a> now for a
                      same-day response.</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
  if (data.general.country_code === 'au') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | About Accident Management" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={AboutHeader}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>About DingGo Accident Management</h1>
                  <div className="entry-content" itemProp="mainContentOfPage">
                    <p>DingGo is an Australian technology company that has developed a unique online, automated end-to-
                      end fleet vehicle repair platform. The platform allows fleet managers, insurers, claims managers and
                      repairers to achieve a faster, easier, automated vehicle repair management process, whilst also
                      providing invaluable data analytics and reporting on their vehicles and repairs.</p>
                    <p>The platform uses technology to offer a fully digital experience. From users capturing the damage on
                      the vehicles, to panel beaters quoting, to users receiving quotes, then selecting a repairer, getting
                      the car repaired and finally leaving a review. Everything is facilitated digitally through our online
                      platform and every repair is tracked end-to-end for fleet managers to easily manage.</p>
                    <p>Our platform is powered by a marketplace of Australia’s biggest independent smash repair network.
                      Utilising this network allows the system to capitalise on unused capacity in the market and connect
                      those who need the work with cars that need work. It also allows those who need their car repaired
                      to be able to simply pick a local repairer near them, at a time that works for them.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <div className="row justify-content-center mb-4">
                <div className="col-12 text-center">
                  <iframe className="youtube-iframe" width="900" height="500" src="https://www.youtube-nocookie.com/embed/KkJjnOVPqkc?si=fQAR2SYfZnvspFxB"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
};

export default AboutAccidentManagement;